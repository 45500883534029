import Component from "../component";

export default class TrainingForm extends Component {
  isOpen = false;
  currentQuestion = 0;
  isContentLoaded = false
  isLoadingContent = false
  selectedTrainingID = null;
  currentSelectionEl = null;

  constructor(...args) {
    super(...args);
  }
  
  init(){
    this.openers = document.querySelectorAll('a[href="#training-form"]');
    this.openers.forEach(element => {
      element.addEventListener("click", this.loadContent.bind(this));
    });
    if (window.location.hash === "#training-form") {
      this.loadContent()
    }
  }

  loadContent(){
    // If already loading just wait
    if(this.isLoadingContent) return
    // If not already initialized load content
    if(!this.isContentLoaded){
      this.fetchTrainingForm().then(() => {
        this._retrieveDom()
        this._bind()
        this.openForm()
        this.isContentLoaded = true
        this.isLoadingContent = false
      })
    }else{
      // already loaded just open popup
      this.openForm()
    }
  }

  /**
   * Fetch the training form content from the WordPress AJAX endpoint
   */
  async fetchTrainingForm() {
    
    this.isLoadingContent = true
    const ajaxUrl = '/wp/wp-admin/admin-ajax.php?action=get_training_form';
    
    await fetch(ajaxUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.success && data.data.html) {
          // Replace the component's HTML with the fetched content
          const wrapper = document.querySelector('div[data-training-form-wrapper]')
          wrapper.innerHTML = data.data.html
        } else {
          console.error('Failed to load training form:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching training form:', error);
      });
  }

  _retrieveDom() {
    
    
    this.closers = [this.dom.component.querySelector(".closer"), this.dom.component.querySelector(".cross")];

    this.stepText = this.dom.component.querySelector(".current-step");
    this.stepRounds = this.dom.component.querySelectorAll(".step-round");

    this.questions = this.dom.component.querySelectorAll(".question");
    this.charaQuestions = this.dom.component.querySelectorAll(".question.character");

    this.charaChoices = new Array(this.charaQuestions.length);
    this.charaQuestions.forEach((_, index) => {
      this.charaChoices[index] = {
        els: this.charaQuestions[index].querySelectorAll(".choice"),
        value: 0
      };
    });

    this.trainingChoices = this.dom.component.querySelectorAll(".choice.training");

    this.nextButtons = this.dom.component.querySelectorAll(".next.button");
    this.prevButtons = this.dom.component.querySelectorAll(".prev.button");

    this.finalNextButton = this.dom.component.querySelector(".button.final-next");
    this.finalPrevButton = this.dom.component.querySelector(".button.final-prev");

    this.finalQuestion = this.dom.component.querySelector(".question.final");
    this.entertainmentQuestions = this.dom.component.querySelector(".entertainment");
    this.designQuestions = this.dom.component.querySelector(".design");

    this.results = this.dom.component.querySelector(".results");
    this.retryButton = this.dom.component.querySelector(".button.retry");

    
  }

  _bind() {
    if (this.charaQuestions.length === 0) {
      return;
    }
    // Show first question
    this.charaQuestions[0].classList.remove("hidden");

    this.closers.forEach(element => {
      element.addEventListener("click", this.closeForm.bind(this));
    });

    this.charaChoices.forEach((charaChoice, index) => {
      charaChoice.els.forEach(answer => {
        answer.addEventListener("click", this.handleAnswerSelection.bind(this, answer, index));
      });
    });

    this.trainingChoices.forEach(choice => {
      choice.addEventListener("click", this.handleTrainingSelection.bind(this, choice));
    });

    this.nextButtons.forEach(nextButton => {
      nextButton.addEventListener("click", this.loadNextStep.bind(this, 1));
    });
    this.prevButtons.forEach(prevButton => {
      prevButton.addEventListener("click", this.loadNextStep.bind(this, -1));
    });

    this.finalNextButton.addEventListener("click", this.handleFinalNextQuestion.bind(this));
    this.finalPrevButton.addEventListener("click", this.handleFinalPrevQuestion.bind(this));

    this.retryButton.addEventListener("click", this.reset.bind(this));
  }

  openForm() {
    this.setGTMStep({ step: 0 });
    document.body.style.overflow = "hidden";
    this.dom.el.classList.remove("hidden");
  }

  closeForm() {
    document.body.style.overflow = "auto";
    this.dom.el.classList.add("hidden");
  }

  setStep(increment) {
    const displayStep = parseInt(this.stepText.textContent) + increment;
    const step = displayStep - 1;
    this.stepText.textContent = displayStep;
    this.stepRounds.forEach(round => round.classList.remove("active"));
    this.stepRounds[step].classList.add("active");
    return step;
  }

  handleAnswerSelection(el, index) {
    if (this.currentSelectionEl !== null) {
      this.currentSelectionEl.classList.remove("active");
    }
    this.nextButtons[index].classList.remove("disabled");
    el.classList.add("active");
    this.currentSelectionEl = el;
    this.charaChoices[index].value = parseInt(this.currentSelectionEl.dataset.type);
  }

  loadNextStep(operator, setGTM = true) {
    const step = this.setStep(operator);
    this.charaQuestions[this.currentQuestion].classList.add("hidden");
    this.currentSelectionEl.classList.remove("active");
    this.nextButtons[this.currentQuestion].classList.add("disabled");
    this.currentQuestion = this.currentQuestion + operator;

    if (this.charaQuestions?.[this.currentQuestion] !== undefined) {
      this.charaQuestions[this.currentQuestion].classList.remove("hidden");
    } else {
      const occurences = this.charaChoices.reduce((acc, { value }) => {
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {});

      const maxValue = Object.keys(occurences).reduce((a, b) => (occurences[a] > occurences[b] ? a : b));

      this.finalQuestion.classList.remove("hidden");

      if (maxValue == 1) {
        this.entertainmentQuestions.classList.remove("hidden");
      } else {
        this.designQuestions.classList.remove("hidden");
      }
    }

    /** GTM */
    if (setGTM) {
      this.setGTMChoice({ event: "quizzStep" });
      this.setGTMStep({ step });
    }
  }

  handleTrainingSelection(el) {
    if (this.currentSelectionEl !== null) {
      this.currentSelectionEl.classList.remove("active");
    }
    this.finalNextButton.classList.remove("disabled");
    el.classList.add("active");
    this.currentSelectionEl = el;
    this.selectedTrainingID = parseInt(this.currentSelectionEl.dataset.id);
  }

  handleFinalNextQuestion() {
    this.results.classList.remove("hidden");
    this.finalQuestion.classList.add("hidden");
    const currentResult = this.results.querySelector('.training-entry[data-id="' + this.selectedTrainingID + '"]')
    if(currentResult){
      currentResult.classList.add("selected");
      /** GTM Result */
      this.setGTMChoice({ event: "quizzResult" });
      this.setGTMStep({ step: 4 });
    }

    this.setStep(1);
  }

  handleFinalPrevQuestion() {
    this.finalQuestion.classList.add("hidden");
    this.entertainmentQuestions.classList.add("hidden");
    this.designQuestions.classList.add("hidden");

    this.finalNextButton.classList.add("disabled");

    this.currentQuestion--;

    this.charaQuestions[this.charaQuestions.length - 1].classList.remove("hidden");

    this.currentStep = this.currentStep - 1;
    this.setStep(-1);
  }

  reset() {
    this.handleFinalPrevQuestion();
    const currentResult = this.dom.component.querySelector(".training-entry.selected")
    if(currentResult){
      currentResult.classList.remove("selected");
    }

    this.setStep(-1);
    for (let i = 1; i < this.charaQuestions.length; i++) {
      this.loadNextStep(-1, false);
    }
    this.setGTMStep({ step: 0 });
    this.results.classList.add("hidden");
  }

  /**
   * Sets GTM step information
   * @param {{ step: number }} params
   * @returns {void}
   */
  setGTMStep({ step }) {
    if (window.dataLayer) {
      const question = this.questions[step];
      const title = question.querySelector(".title");
      const titleText = title?.innerHTML.trim() || `Question ${step + 1}`;

      let payload = {
        event: "quizz",
        step: (step + 1).toString(),
        stepName: titleText
      };

      const isFinalStep = step === 4;
      if (isFinalStep) {
        const formationEl = this.results.querySelector(".training-entry.selected .title");
        if (formationEl) {
          payload.formation = formationEl.innerHTML.trim();
        }
      }

      window.dataLayer.push(payload);
    }
  }

  /**
   * Sets GTM user choice
   * @param {{ choice: text, event: 'quizzResult' | 'quizzStep' }} params
   * @returns {void}
   */
  setGTMChoice({ event }) {
    if (window.dataLayer) {
      const textEl = this.currentSelectionEl.querySelector(".text");
      const choice = textEl?.innerHTML.trim() || "unknown";

      window.dataLayer.push({
        event,
        choice
      });
    }
  }

  unbind() {
    this.openers.forEach(element => {
      element.removeEventListener("click", this.openForm.bind(this));
    });

    this.closers.forEach(element => {
      element.removeEventListener("click", this.closeForm.bind(this));
    });
  }
}
